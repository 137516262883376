@charset "utf-8";

// COLORS
$theme-colors: (
  "primary": rgb(249, 207, 107),/// hsla(124, 68%, 34%, 0.44),
  "secondary": #6c757d,
  "success": #28a745,
  "danger": #ff4136,
  "warning": #ffc107,
  "info": #17a2b8,
  "light": #f8f9fa,
  "dark": #343a40,
  "white": #ffffff,
  "transparent": transparent,
);

// TEXT
$font-family-sans-serif: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

#Navbar{

  font-weight: bold;
  font-size: 1.2em;
}

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;



  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

.planListItem{
  padding-top: 1%;
margin-bottom: 1em;
  min-height: 26em;
  max-width: 20em;
  border: 1px solid rgb(0, 0, 0) !important;
justify-self: center;
align-items: stretch;
margin: 1em;
width: 15em;
height: 25em;
}
.button {
font-size: 1.1em;
align-items: center;
width: 7em;


}

.frontPlans h1{

  padding-top:2em;
}

.PlansFront {
width: 100%;
max-width: 80em;
  margin-top: 30px;
  text-align: center;
  min-height: 100vh;
  display: inline-flex;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: fit-content ;
justify-content: center;
justify-content: center;
  width: 100%;
  


  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0.3;

  h1 {
    padding-top: 1em;
    overflow-wrap: break-word;

    white-space: normal;
    font-size: 2.125rem;
    margin-bottom: 0.75rem;
  }

  h2 {
    overflow-wrap: break-word;

    font-size: 1.75rem;
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  h3 {
    overflow-wrap: break-word;
    text-align: center;
    font-size: 1.25rem;
    &:not(:first-child) {
      margin-top: 1.5rem;
      margin-bottom: 2em; 
      padding: 1em;
      min-height: 20em;
      font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;

  

    }
  }
  
  }
}
/*.top-space{
width: 100%;
height: 5em; 
z-index: -1;

}*/

#Container h3{
  max-width: 90% !important;
  padding-top: .5em !important;
align-self: center;
justify-items: center;
text-transform: uppercase !important;

}
.PlansFront li{
  
  display: inline-flex;
  border: 1px gray;
  text-align: left;
height:fit-content;


}
  
#CarouselSection{
margin-top: 5em;

}
.PlansFront p{
    text-align: justify;
    overflow-wrap: break-word;
    max-width: 300px;
    margin: 5px;
    padding: 10px;
    line-height: 1.5;
    font-size: 16px;
    max-height: 10em;
    min-height: 200px;
    overflow: hidden;
    
  
  }
img{
  justify-content:center;
  overflow: hidden;
  max-height:40em;
  flex-shrink: 1;
  display: flex;
}
  #Carousel{
    max-height: 60em;
    margin-top: 5em;
  }
  #Carousel h1{ margin-top: 3em;

  }
  .PlansFront a{
    text-align: justify;
    overflow-wrap: break-word;
    max-width: 300px;
    padding: 10px;
    line-height: 1.5;
    min-height: 200px;
    font-weight: bold;
    cursor: pointer;
    color:rgba(13, 9, 0, 0.497);

    text-decoration: none;
    overflow-wrap: break-word;
  }