.PlansFront {
  height:fit-content;
    width: 100%;
  
    // Add bottom margin if element below
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  
    &__subtitle {
      // Subtitle text generally isn't very long
      // so usually looks better to limit width.
      max-width: 700px;
      // So we can have max-width but still
      // have alignment controlled by text-align.
      display: inline-flexbox;
    }}
.planListItem {
  border: 1px solid black;

    // Add bottom margin if element below
    &:not(:last-child) {
      margin-top: 1em;
      margin-bottom: 2rem;
    }
  
    &__subtitle {
      // Subtitle text generally isn't very long
      // so usually looks better to limit width.
      max-width: 300px;
      // So we can have max-width but still
      // have alignment controlled by text-align.
      display: inline;
    
    }}

    .planListItem h3{
      padding: 0.5em;
      overflow-wrap: break-word;
      text-align: center;
      font-size: 1.25rem;
      &:not(:first-child) {
        margin-top: 1.5rem;
        margin-bottom: 2em; 
        padding: 1em;
        min-height: 20em;
        font-size: 18px;}
      font-weight: bold;
      text-transform: uppercase;
      
  
    
    
  }

  .cities{
    justify-self: left;
  }
  .cities b{
    font-size: .8em;
    color: grey;
    padding-top: 1.5em;
    display: inline;
    margin-left:.5em;

  }

  .cities li{
font-size: 1.2em;
font-weight: bold;
border: 1px solid black;
border-radius: .3em;
padding: .3em;

  }
